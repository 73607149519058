<template>
	<v-layout justify-center px-5 py-3 row wrap>
		<v-flex v-for="quickfolder in quickfolders" :key="quickfolder.id" ma-2 shrink @click="openFileExplorer(quickfolder.id)">
			<Drop
				:class="`v-card v-card--hover v-sheet v-sheet--tile ${dark ? 'theme--dark' : ''}`"
				:style="`${hoveredQuickfolderId == quickfolder.id ? 'background-color:rgba(var(--v-primary-transparent), 0.25);' : ''}height:8em;max-width:${
					$vuetify.breakpoint.smAndUp ? '90vw' : '100%'
				};width:20em`"
				@dragenter="(name, event) => onDragEnter(event, quickfolder.id)"
				@dragleave="(name, event) => onDragLeave(event, quickfolder.id)"
				@drop="(name, event) => onDrop(event, quickfolder.id)"
			>
				<v-layout align-center column fill-height justify-space-around pa-2 style="position: relative">
					<v-flex v-if="showSettings" shrink style="position: absolute; right: 0; top: 0">
						<v-btn icon small @click.stop="openSettings(quickfolder)">
							<v-icon color="secondary" small>settings</v-icon>
						</v-btn>
					</v-flex>
					<v-flex class="headline" shrink text-xs-center v-text="quickfolder.name" />
				</v-layout>
			</Drop>
		</v-flex>
		<WDialog v-model="dialog" max-width="700px" no-padding :title="$t('quickfolders.automation_title', { quickfolder: selectedQuickfolder.name })">
			<QuickfolderApplicationForm :value="selectedQuickfolder" @input="updateQuickfolder($event)" />
		</WDialog>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'
import { Drop } from 'vue-drag-drop'

export default {
	name: 'QuickfoldersList',
	components: {
		Drop,
		QuickfolderApplicationForm: () => ({
			component: import('@/components/Eurex/Quickfolders/QuickfolderApplicationForm')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	data: function () {
		return {
			dialog: false,
			hoveredQuickfolderId: null,
			quickfolders: [],
			selectedQuickfolder: {},
			dragCounter: 0
		}
	},
	computed: {
		...mapState({
			dark: state => state.user.darkMode,
			isAccountant: state => state.auth.isAccountant
		}),
		showSettings: function () {
			return this.isAccountant
		}
	},
	watch: {
		vendorId: {
			handler: function (val) {
				if (val) {
					this.getQuickfolders()
				}
			},
			immediate: true
		}
	},
	methods: {
		getQuickfolders: function () {
			const vendorId = this.vendorId
			return this.service.getQuickfolders(vendorId).then(quickfolders => {
				this.quickfolders = quickfolders
			})
		},
		onDragEnter: function (event, quickfolderId) {
			event.preventDefault()
			event.stopPropagation()
			this.dragCounter++
			this.hoveredQuickfolderId = quickfolderId
		},
		onDragLeave: function (event, quickfolderId) {
			event.preventDefault()
			event.stopPropagation()
			this.dragCounter--
			if (this.hoveredQuickfolderId === quickfolderId && this.dragCounter === 0) {
				this.hoveredQuickfolderId = null
			}
		},
		onDrop: function (event, quickfolderId) {
			event.preventDefault()
			event.stopPropagation()
			const files = Array.from(event.dataTransfer.files)
			this.uploadFiles(files, quickfolderId)
			this.hoveredQuickfolderId = null
		},
		openFileExplorer: function (quickfolderId) {
			const fileInput = document.createElement('input')
			fileInput.multiple = true
			fileInput.type = 'file'
			fileInput.addEventListener('input', e => {
				e.preventDefault()
				const files = e.target.files
				this.uploadFiles(files, quickfolderId)
			})
			fileInput.click()
		},
		openSettings: function (quickfolder) {
			this.selectedQuickfolder = quickfolder
			this.dialog = true
		},
		uploadFiles: function (files, quickfolderId) {
			// TODO: migrate to DocumentManagerService
			this.service.uploadItems(this.vendorId, Array.from(files), quickfolderId, false)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('documents.messages.document_sent'))
				})
		},
		updateQuickfolder: function (quickfolder) {
			const index = this.quickfolders.findIndex(({ id }) => id == quickfolder.id)

			if (index != -1) {
				this.quickfolders[index].applications = quickfolder.applications
			}
		}
	}
}
</script>
